import {PERMISSOES} from "@core/utils/permissoes";
import {TipoPerfil} from "@core/enums/perfil.enum";

export interface MenuType {
  url?: string;
  label: string;
  exact?: boolean;
  profiles?: string[];
  permissoes?: string[];
}

export const MENUS_NAVBAR: MenuType[] = [
  {
    url: '',
    label: 'Início',
    exact: true
  },
  {
    url: '/configuracao',
    label: 'Configurações'
  },
  {
    url: '/avaliacao-desempenho',
    label: 'Avaliação de Desempenho'
  },
  {
    url: '/ferias',
    label: 'Férias'
  },
  {
    url: '/profissionais',
    label: 'Profissionais'
  },
  {
    url: '/quadro-funcional',
    label: 'Quadro Funcional'
  },
  {
    url: '/afastamentos',
    label: 'Afastamentos'
  },
  {
    url: '/ficha-financeira',
    label: 'Ficha Financeira',
    profiles: [TipoPerfil.ADM, TipoPerfil.SERV],
    permissoes: [PERMISSOES.FichaFinanceira_detalhar]
  }
];
